import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, matchPath } from 'react-router-dom';
import { get as getProperty, isFunction } from 'lodash';
import {
  loadPack,
  loadChildPacks,
  selectPackTalent,
  deselectPackTalent,
  selectAllPackTalent,
  deselectAllPackTalent,
  subscribeToPackTalents,
  subscribeToChildPackTalents,
} from 'actions';

import './index.css';
import Paper from 'material-ui/Paper';
import TalentCard from 'ui/OldTalentCard';
import Container from 'ui/Container';
import Title, { Tab, Action } from 'ui/Title';
import Button from 'ui/Button';
import MessageSelectedDialog from '../MessageSelectedDialog';


export const mapStateToProps = (state, ownProps) => {
  const { url, path } = ownProps.match;

  const packId = ownProps.match.params.packId;

  const pack = state.packs.all[packId] || {};
  const childPacks = state.packs.byParent[packId] || {};

  const categories = pack.categories || {};
  for (const childPackId in childPacks) {
    const childPack = childPacks[childPackId];
    const childCategories = childPack.categories || {};
    for (const categoryId in childCategories) {
      const childCategory = childCategories[categoryId];
      categories[categoryId] = `${childPack.agencyName || ""} ${childCategory || ""}`.trim();
    }
  }

  let talents = state.packTalents.byPack[packId] || {};
  for (const childPackId in childPacks) {
    talents = { ...talents, ...(state.packTalents.byPack[childPackId] || {}) };
  }

  const canMessageTalent = getProperty(state, 'user.permissions.canMessageTalents', false);

  return {
    url,
    path,
    packId,
    talents,
    categories,
    pack,
    canMessageTalent,
    isLoggedIn: state.user.isLoggedIn,
  };
};

export const mapDispatchToProps = {
  loadPack,
  loadChildPacks,
  selectPackTalent,
  deselectPackTalent,
  selectAllPackTalent,
  deselectAllPackTalent,
  subscribeToPackTalents,
  subscribeToChildPackTalents,
};



export class PackShortlist extends Component {

  state = {
    isMessageTalentsDialogOpen: false,
  }

  async componentDidMount() {
    if (!this.props.packId) {
      return;
    }
    this.props.loadPack(this.props.packId);

    this.unsubscribeFromPackTalents = await this.props.subscribeToPackTalents(this.props.packId);
    this.unsubscribeFromChildPackTalents = await this.props.subscribeToChildPackTalents(this.props.packId);
  }

  componentWillUnmount() {
    if (isFunction(this.unsubscribeFromPackTalents)) {
      this.unsubscribeFromPackTalents();
    }
    if (isFunction(this.unsubscribeFromChildPackTalents)) {
      this.unsubscribeFromChildPackTalents();
    }
  }

  openMessageTalentsDialog = () => this.setState({ isMessageTalentsDialogOpen: true })
  closeMessageTalentsDialog = () => this.setState({ isMessageTalentsDialogOpen: false })

  selectAll = () => {
    const match = matchPath(window.location.pathname, { path: `${this.props.path}/:categoryId`, exact: true });
    if (match) {
      this.props.selectAllPackTalent(this.props.packId, match.params.categoryId);
    }
    else {
      this.props.selectAllPackTalent(this.props.packId);
    }
  }

  selectNone = () => {
    const match = matchPath(window.location.pathname, { path: `${this.props.path}/:categoryId`, exact: true });
    if (match) {
      this.props.deselectAllPackTalent(this.props.packId, match.params.categoryId);
    }
    else {
      this.props.deselectAllPackTalent(this.props.packId);
    }
  }

  renderShortlist = () => {
    const { packId, talents, deselectPackTalent } = this.props;

    const talentCards = Object.keys(talents)
      .map(id => ({ id, ...talents[id] }))
      .filter(talent => talent.isSelected)
      .sort((a, b) => a.lastName > b.lastName ? 1 : -1)
      .map(talent =>
        <TalentCard
          view="small"
          className="packshortlist-selectedtalent"
          talentId={talent.id}
          key={talent.id}
          packId={packId}
          talent={talent}
          actions={
            <Button
              icon="close"
              onClick={() => deselectPackTalent(talent.packId || packId, talent.id)}
            />
          }
        />
      );

    if (talentCards.length === 0) {
      return (
        <div className="packshortlist-notalent">
          Shortlist Empty
        </div>
      );
    }

    return talentCards;
  }

  renderTalent = (categoryId) => {
    const {
      packId,
      talents,
      selectPackTalent,
      deselectPackTalent,
    } = this.props;

    let toRender = Object.keys(talents).map(id => ({ id, ...talents[id] }));

    if (categoryId && categoryId !== "all") {
      toRender = toRender.filter(talent => {
        if (talent.category == categoryId) {
          return true;
        }
        for (let id in talent?.categories || {}) {
          if (talent.categories[id] == categoryId) {
            return true;
          }
        }
        return false;
      });
    }

    toRender.sort((a, b) => a.lastName > b.lastName ? 1 : -1);

    return toRender
      .map(talent =>
        <Paper key={talent.id}>
          <TalentCard
            view="medium"
            talentId={talent.id}
            packId={packId}
            talent={talent}
            isShortlist={true}
            isSelected={talent.isSelected}
            onSelect={() => {
              talent.isSelected
                ? deselectPackTalent(talent.packId || packId, talent.id)
                : selectPackTalent(talent.packId || packId, talent.id);
            }}
          />
        </Paper>
      );
  }

  render() {

    if (!this.props.pack) {
      return;
    }
    const { packId } = this.props;

    const talents = this.props.talents;
    const categories = this.props.categories;
    const tabs = Object.keys(categories)
      .map(id => ({ id, name: categories[id] }))
      .map(category =>
        <Tab
          path={`${this.props.path}/${category.id}`}
          to={category.id}
          key={category.id}
          label={category.name}
        />
      );

    tabs.unshift(
      <Tab
        path={`${this.props.path}/all`}
        to="all"
        key="all"
        label="All"
      />
    );

    const numSelected = Object.keys(talents)
      .map(id => ({ id, ...talents[id] }))
      .filter(talent => talent.isSelected)
      .length;

    const actions = [
      <Action
        icon="selectAll"
        tooltip="Select All"
        onClick={this.selectAll}
      />,
      <Action
        icon="selectNone"
        tooltip="Select None"
        onClick={this.selectNone}
      />
    ];

    if (this.props.canMessageTalent) {
      actions.push(
        <Action
          tooltip="Message Talent"
          icon="multimessage"
          onClick={numSelected > 0 ? this.openMessageTalentsDialog : null}
          disabled={numSelected === 0}
        />
      );
    }

    actions.push(
      <Action
        tooltip="Download Selected Talent"
        icon="download"
        onClick={numSelected > 0 ? () => this.downloadLink.click() : null}
        disabled={numSelected === 0}
      />
    );

    return (
      <Container>
        <Title
          title="Shortlist"
          subTitle={`${numSelected} talent selected`}
          tabs={tabs}
          actions={actions}
          style={{ marginBottom: 0 }}
        />

        <Switch>
          <Route path={`${this.props.path}/:categoryId`} render={props =>
            <div className="packshortlist-wrapper">
              <div
                className="packshortlist-selected"
                style={{
                  top: this.props.isLoggedIn ? 60 : 0
                }}>
                {this.renderShortlist()}
              </div>
              <div className="packshortlist-all">
                <div className="packshortlist-grid">
                  {this.renderTalent(props.match.params.categoryId)}
                </div>
              </div>
            </div>
          } />
          <Redirect to={`${this.props.url}/all`} />
        </Switch>

        <MessageSelectedDialog
          packId={this.props.packId}
          open={this.state.isMessageTalentsDialogOpen}
          onSend={this.closeMessageTalentsDialog}
          onRequestClose={this.closeMessageTalentsDialog}
        />

        <a
          ref={el => this.downloadLink = el}
          style={{
            position: 'absolute',
            left: -1000,
          }}
          href={`https://us-central1-skybolt-99adb.cloudfunctions.net/packCSV?packId=${packId}&selected=true`}
          download={true}
          rel="noopener noreferrer"
          target="_blank">
        </a>

      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackShortlist);