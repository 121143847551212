import React, { Component } from 'react';
import { connect } from 'react-redux';
import { range, debounce, get } from 'lodash';
import {
  loadPackTalents,
  renamePackCategory,
  addPackCategory,
  removePackCategory,
  removePackTalent,
  addPackTalent,
  addPackTalentsFromSearch,
  setActiveCategory,
  setAgencyTalentsSearchMode,
  loadMoreAgencyTalentsSearchResults,
  searchAgencyTalents,
} from 'actions';

import "./index.css";

import TalentCard from 'ui/OldTalentCard';
import Paper from 'material-ui/Paper';

import TalentCard2 from 'ui/TalentCard';

// import Card from 'ui/Card';
import Container from 'ui/Container';
import Title, { Action, Filter, Tab } from 'ui/Title';
// import TalentSearch from 'ui/TalentSearch';
import {TalentSearch, Card} from 'skybolt-ui';
import MessageTalentDialog from 'ui/MessageTalentDialog';
import Icon from 'ui/Icon';
import TextField from 'ui/TextField';
import Button from 'ui/Button';
import Dialog from 'ui/Dialog';





const mapStateToProps = (state, ownProps) => {

  // Get the user agencies.
  const agencyIds = state.user.agencyIds || [];
  const agencies = agencyIds.map(id => state.agencies.all[id]).filter(agency => !!agency);

  const packId = ownProps.match.params.packId;
  const agency = state.agencies.all[ state.user.agencyId ] || {};
  let talentFields = agency.talentFields || {};
  // if(talentFields.dob) {
    talentFields.age = {
      type: 'number',
      name: "Age",
      min: 0,
      max: 100,
      units: "years",
      order: -1,
      // category: talentFields.dob.category,
    };
  // }

  const pack = state.packs.all[packId];
  const talentTags = agency.talentTags || [];
  const mediaTags = agency.mediaTags || [];

  const canEditTalents = get(state, 'user.permissions.canEditTalents', false);
  const canEditPack = pack.userId === state.user.id || (state.user.agencyId && pack.agencyId === state.user.agencyId);

  const limitToDivision = state.user.activeDivision || false;

  return {
    agencies,
    agency,
    packId: packId,
    activeCategoryId: state.activeCategoryId,
    pack,
    talents: state.packTalents.byPack[packId] || {},

    talentFields,
    genderField: talentFields['gender'],
    ageField: talentFields['age'],
    talentTags,
    mediaTags,
    limitToDivision,

    mode: state.agencyTalents.searchMode,
    searchResults: state.agencyTalents.searchResults,
    searchCount: state.agencyTalents.searchCount,
    search: state.agencyTalents.search,

    canEditTalents,
    canEditPack,
  };
};

const mapDispatchToProps = {
  loadPackTalents,
  renamePackCategory,
  addPackCategory,
  removePackCategory,
  removePackTalent,
  addPackTalent,
  addPackTalentsFromSearch,
  setActiveCategory,

  loadMoreAgencyTalentsSearchResults,
  searchAgencyTalents,
  setAgencyTalentsSearchMode,
};




class PackTalent extends Component {

  state = {
    isAlertDialogOpen: false,
    alertText: "",
    isSelectAllDialogOpen: false,
    isSelecting: false,
    isMessageTalentDialogOpen: false,
    talentId: null,
    search: {},
    isRemoveCategoryDialogOpen: false,
    categoryToRemove: null,
  }

  componentDidMount() {
    this.props.loadPackTalents(this.props.packId);

    this.setDefaultCategoryIfNeeded(this.props);
    this.search(this.props.search);

    document.body.addEventListener('scroll', this.handleDocumentScroll);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.packId !== nextProps.packId) {
      this.props.loadPackTalents(nextProps.packId);
    }
    this.setDefaultCategoryIfNeeded(nextProps);
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.handleDocumentScroll);
  }


  handleDocumentScroll = e => {
    const scrollY = document.body.scrollTop;
    const windowHeight = window.innerHeight;
    const indicatorTop = this.scrollIndicator.offsetTop;
    if(indicatorTop - (scrollY + windowHeight) < 300) {
      this.props.loadMoreAgencyTalentsSearchResults();
    }
  }


  setDefaultCategoryIfNeeded = (props) => {
    const { activeCategoryId, pack, setActiveCategory } = props;
    const categories = pack.categories || {};

    if(!activeCategoryId && !!pack) {
      setActiveCategory(Object.keys(categories)[0]);
    }

    if(activeCategoryId && pack && !categories[activeCategoryId]) {
      setActiveCategory(Object.keys(categories)[0]);
    }
  }

  handleMessageTalent = talentId => {
    this.setState({
      isMessageTalentDialogOpen: true,
      talentId,
    });
  }
  closeMessageDialog = () => this.setState({isMessageTalentDialogOpen:false})

  openAlertDialog = alertText => this.setState({isAlertDialogOpen:true, alertText})
  closeAlertDialog = () => this.setState({isAlertDialogOpen:false})

  openSelectAllDialog = () => this.setState({isSelectAllDialogOpen:true})
  closeSelectAllDialog = () => this.setState({isSelectAllDialogOpen:false})

  openRemoveCategoryDialog = categoryToRemove => this.setState({isRemoveCategoryDialogOpen:true, categoryToRemove})
  closeRemoveCategoryDialog = () => this.setState({isRemoveCategoryDialogOpen:false, categoryToRemove:null})


  searchAgencyTalents = debounce(() => {
    this.props.searchAgencyTalents({
      ...this.state.search,
      filterByTags: this.state.search.applyMediaTags ? this.state.search.mediaTags : null,
    });
  }, 300)

  search = search => {
    if(search.tag) {
      search.tags = [...search.tag];
    }
    this.setState({search}, this.searchAgencyTalents);
  }

  searchAgency = agencyId => {
    this.search({...this.state.search, agencyId});
  }

  advanced = () => {
    this.props.setAgencyTalentsSearchMode('advanced');
  }

  simple = () => {
    this.setState({search:{}}, () => this.props.setAgencyTalentsSearchMode('simple'));
  }

  addCategory = () => {
    const categoryId = this.props.addPackCategory(this.props.pack.id);
    this.props.setActiveCategory(categoryId);
  }

  selectAll = async () => {
    const {
      activeCategoryId,
      addPackTalentsFromSearch,
      pack,
      agency,
      limitToDivision,
    } = this.props;

    if(!pack.id) {
      return;
    }

    if(!activeCategoryId) {
      this.openAlertDialog("No category select. Please select a category and try again.");
      return;
    }

    this.setState({isSelectAllDialogOpen:false});
    await addPackTalentsFromSearch(
      pack.id, activeCategoryId, 
      this.state.search.applyMediaTags ? this.state.search.mediaTags : [], 
      this.state.search.includePrivateMedia,
      agency.filterTalentMediaByDivision && limitToDivision ? limitToDivision : false
    );
  }

  removeCategory = categoryId => {
    this.props.removePackCategory(this.props.pack.id, categoryId);
    this.closeRemoveCategoryDialog();
  }



  renderTitle() {
    const search = this.state.search;

    const {
      mode,
      agencies,
      ageField,
      genderField,
      searchCount,
      canEditTalents,
      talentFields,
      talentTags,
      mediaTags,
    } = this.props;

    const actions = [];
    const filters = [];
    const tabs = [];
    let onSearch = null;

    let subtitle = searchCount ? `${searchCount} talent found` : '';
    let agency = null;

    // Do we hide the age search?
    // - if search is showing a specific agency, look at that agencies settings, or
    // - go through all agency settings.
    let showAgeSearch = false;
    if(canEditTalents) {
      showAgeSearch = true;
    }
    else if(!!search.agencyId) {
      agency = agencies.find(a => a.id === search.agencyId);
      showAgeSearch = agency && agency.showTalentDOBPublic !== false;
    }
    else {
      showAgeSearch = true;
      for(const agency of agencies) {
        if(agency.showTalentDOBPublic === false) {
          showAgeSearch = false;
        }
      }
    }

    if(mode !== 'advanced') {
      onSearch = query => this.search({...search, query});

      if(showAgeSearch) {
        filters.push(
          <Filter
            label="Age"
            value={search.age}
            options={range(ageField.min, ageField.max, ageField.step || 1)}
            onChange={age => this.search({...search, age})}
            style={{minWidth:100}}
          />
        );
      }

      if(genderField) {
        filters.push(
          <Filter
            label="Gender"
            value={search.gender}
            options={genderField.options}
            onChange={gender => this.search({...search, gender})}
            style={{minWidth:120}}
          />
        );
      }

      actions.push(
        <Action
          icon={<Icon name="tune"/>}
          tooltip="Advanced Search"
          onClick={this.advanced}
        />
      );
    }
    else {
      actions.push(
        <Action
          icon={<Icon name="close"/>}
          tooltip="Clear Search"
          onClick={this.simple}
        />
      );

    }

    actions.push(
      <Action
        icon={<Icon name="selectAll"/>}
        tooltip="Select All"
        onClick={() => this.props.searchCount < 100 ? this.selectAll() : this.openSelectAllDialog()}
      />
    );

    actions.push(
      <Action
        tooltip="Download Talent"
        icon="download"
        onClick={() => this.downloadLink.click()}
        disabled={false}
      />
    );

    if(agencies.length > 0) {
      tabs.push(
        <Tab label="All" isActive={!search.agencyId} onClick={() => this.searchAgency(null)}/>
      );
      agencies.forEach(agency => {
        tabs.push(
          <Tab label={agency.name} isActive={search.agencyId === agency.id} onClick={() => this.searchAgency(agency.id)}/>
        );
      });
    }

    return (
      <Container>
        <Title
          search={search.query}
          onSearch={onSearch}
          actions={actions}
          filters={filters}
          tabs={tabs}
          title="Talent"
          subTitle={subtitle}
          style={{marginBottom:0}}
        />

        {mode === 'advanced' &&
          <Card style={{padding:16}}>
            <TalentSearch
              fields={talentFields}
              value={search}
              onChange={search => this.search(search)}
              tags={talentTags}
              statuses={['active', 'incomplete', 'trial', 'paid', 'inactive', 'expired', 'canceled']}
              mediaTags={mediaTags}
            />
          </Card>
        }
      </Container>
    );
  }

  renderListCategoryTalents(categoryId) {
    const {
      talents,
      pack,
      removePackTalent,
    } = this.props;

    // cateogry 1668464506071
    // talent -N8vgHgXqLLwe4KId63H

    return Object.keys(talents || {})
      .map(id => talents[id])
      .filter(talent => {
        if(talent && talent.category == categoryId) {
          return true;
        }
        for(let id in talent?.categories || {}) {
          if(talent.categories[id] == categoryId) {
            return true;
          }
        }
        return false;
      })

      .sort((a, b) => a.lastName > b.lastName ? 1 : -1)
      .map(talent => {
        return (
          <TalentCard
            view="small"
            className="packtalents-list-talent"
            key={talent.id}
            talentId={talent.id}
            packId={pack.id}
            talent={talent}
            actions={
              <Button icon="trash" onClick={() => removePackTalent(pack.id, talent.id, categoryId)}/>
            }
          />
        );
      });
  }

  renderListCategory = categoryId => {
    const {
      pack,
      canEditPack,
      activeCategoryId,
      renamePackCategory,
      setActiveCategory,
    } = this.props;

    const icon = activeCategoryId === categoryId ? 'check' : 'uncheck';

    return (
      <div className="packtalents-category" key={categoryId}>
        <div className="packtalents-category-name">
          <Button icon={icon} onClick={() => setActiveCategory(categoryId)}/>
          {canEditPack &&
            <TextField
              className="packtalents-category-name-input"
              name="categoryId"
              placeholder="Category Name"
              initialValue={pack.categories[categoryId]}
              onChangeText={(val) => renamePackCategory(pack.id, categoryId, val)}
              autoSelect={true}
            />
          }
          {!canEditPack &&
            <div className="packtalents-category-name-text">{pack.categories[categoryId]}</div>
          }
          {canEditPack &&
            <Button
              icon="trash"
              className="packtalents-category-remove"
              onClick={() => this.openRemoveCategoryDialog(categoryId)}
            />
          }
        </div>
        {this.renderListCategoryTalents(categoryId)}
      </div>
    );
  }

  renderListCategories() {
    return Object.keys(this.props.pack.categories || {}).sort((a, b) => a > b ? 1 : -1).map(this.renderListCategory);
  }

  renderList() {
    return (
      <div className="packtalents-list">

        {this.renderListCategories()}

        {this.props.canEditPack &&
          <Button
            label="Add Category"
            raised={true}
            onClick={this.addCategory}
          />
        }

      </div>
    );
  }

  renderGrid() {
    const {
      pack,
      searchResults,
      talents,
      activeCategoryId,
      removePackTalent,
      addPackTalent,
      canEditTalents,
    } = this.props;

    return (
      <div className={`packtalents-grid ${!canEditTalents ? 'is-compact' : ''}`}>
        {Object.keys(searchResults)
          .map(talentId => searchResults[talentId])
          .sort((a, b) => a.order < b.order ? -1 : 1)
          .map(talent => {
            let packTalent = talents[talent.id];

            let isSelected = false;
            if(packTalent) {
              if(packTalent.category == activeCategoryId) {
                isSelected = true;
              }
              for(let id in packTalent.categories || {}) {
                if(packTalent.categories[id] === activeCategoryId) {
                  isSelected = true;
                }
              }
            }

            let isAltSelected = !isSelected && Object.keys(talents).indexOf(talent.id) !== -1;

            const onSelect = isSelected 
              ? () => removePackTalent(pack.id, talent.id, activeCategoryId) 
              : () => addPackTalent(
                pack.id, 
                activeCategoryId, 
                talent.id, 
                this.state.search.applyMediaTags ? this.state.search.mediaTags : [], 
                this.state.search.includePrivateMedia,
                this.props.agency.filterTalentMediaByDivision && this.props.limitToDivision ? this.props.limitToDivision : false
              );

            // return (
            //   <Card key={talent.id}>
            //     <TalentCard
            //       zDepth={0}
            //       packId={pack.id}
            //       talent={talent}
            //       isSelected={isSelected}
            //       isAltSelected={isAltSelected}
            //       onSelect={onSelect}
            //       onNewMessageClick={() => this.handleMessageTalent(talent.id)}
            //     />
            //   </Card>
            // );

            if(!canEditTalents) {
              return (
                <Paper key={talent.id}>
                  <TalentCard
                    view="medium"
                    talentId={talent.id}
                    talent={talent}
                    isSelected={isSelected}
                    isAltSelected={isAltSelected}
                    onSelect={onSelect}
                    onClick={() => this.props.history.push({pathname:`/talents/${talent.id}/info`, state:{modal:true, pack:pack.id}})}
                  />
                </Paper>
              );
            }

            return (
              <TalentCard2
                key={talent.id}
                talentId={talent.id}
                onClick={(e, tab="info") => {
                  this.props.history.push({
                    pathname:`/talents/${talent.id}/${tab}`,
                    state:{
                      modal: true,
                      packId: pack.id,
                    }
                  });
                }}
                onNewMessageClick={() => this.handleMessageTalent(talent.id)}
                isSelected={isSelected}
                isAltSelected={isAltSelected}
                onSelect={onSelect}
              />
            );
          })
        }
        <div ref={el => this.scrollIndicator = el}></div>
      </div>
    );
  }

  render() {
    if(!this.props.pack) {
      return null;
    }

    return (
      <div className="packtalents-wrapper">

        {this.renderTitle()}

        <Container className="packtalents-talents">
          {this.renderList()}
          {this.renderGrid()}
        </Container>

        <MessageTalentDialog
          open={this.state.isMessageTalentDialogOpen}
          onRequestClose={this.closeMessageDialog}
          talentId={this.state.talentId}
        />

        <Dialog
          open={this.state.isAlertDialogOpen}
          onRequestClose={this.closeAlertDialog}
          contentStyle={{maxWidth:500}}>

          <div style={{paddingBottom:40}}>{this.state.alertText}</div>

          <div style={{textAlign:'right'}}>
            <Button raised={true} primary={true} label="Ok" onClick={this.closeAlertDialog}/>
          </div>
        </Dialog>

        <Dialog
          open={this.state.isSelectAllDialogOpen}
          onRequestClose={this.closeSelectAllDialog}
          contentStyle={{maxWidth:500}}
          title="Are you sure?"
          actions={[
            <Button key="no" raised={true} label="No" onClick={this.closeSelectAllDialog}/>,
            <Button key="yes" raised={true} primary={true} label="Yes" onClick={this.selectAll}/>
          ]}>

          <div>You are selecting {this.props.searchCount} talent.</div>

        </Dialog>


        <Dialog
          open={this.state.isRemoveCategoryDialogOpen}
          onRequestClose={this.closeRemoveCategoryDialog}
          contentStyle={{maxWidth:500}}
          title="Are you sure?"
          actions={[
            <Button raised={true} label="No" onClick={this.closeRemoveCategoryDialog}/>,
            <Button raised={true} primary={true} label="Yes" onClick={() => this.removeCategory(this.state.categoryToRemove)}/>
          ]}>

          <div>Are you sure you want to remove this category?</div>

        </Dialog>

        <a
          ref={el => this.downloadLink = el}
          style={{
            position: 'absolute',
            left: -1000,
          }}
          href={`https://us-central1-skybolt-99adb.cloudfunctions.net/packCSV?packId=${this.props.pack.id}`}
          download={true}
          rel="noopener noreferrer"
          target="_blank">
        </a>

      </div>
    );

  }
}




export default connect(mapStateToProps, mapDispatchToProps)(PackTalent);
