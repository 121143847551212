/* global skyboltsnow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { isString, isArray, uniq } from 'lodash';
import { Text, InputSelect, Modal } from 'skybolt-ui';
import {
  updateTalent,
  addTalentEmail,
  addTalentPhone,
  removeTalentEmail,
  removeTalentPhone,
  updateTalentEmail,
  updateTalentPhone,
  cancelTalent,
  sendTalentEmailVerification,
} from 'actions';
import { defaultTalentFields } from 'config';

import IconMenu from 'material-ui/IconMenu';

import Button from 'ui/Button';
import Dialog from 'ui/Dialog';
import Subtitle from 'ui/Subtitle';
import Page from 'ui/Page';
import Field from 'ui/Field';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import NumberField from 'ui/NumberField';
import RangeField from 'ui/RangeField';
import MenuItem from 'material-ui/MenuItem';
import DateField from 'ui/DateField';
import SSNField from 'ui/SSNField';

import BankAccount from 'App/Talents/Talent/Info/BankAccount';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId;

  const user = state.user;

  const talent = state.talents.all[talentId];
  const emails = talent.emails || {};
  const phones = talent.phones || {};

  const agency = talent ? state.agencies.all[talent.agencyId] : null;

  const isOwnTalent = user.talents && user.talents[talentId];


  // Hide divisions and labels from talent.

  const canAdmin = !!user.permissions.canEditTalents || !!user.permissions.canAdminTalents;
  const useBillCom = !!agency && !!agency.useBilling && !!agency.billingOrgId;
  const canBilling = !!user.permissions.canAdminBilling || (user.permissions.canAgencyBilling && talent.agencyId === user.agencyId);
  const canTag = canAdmin || (agency && agency.allowTalentToSelectDivision);


  return {
    user,
    talentId,

    talent,
    emails,
    phones,
    agency,

    isOwnTalent,
    canAdmin,
    useBillCom,
    canBilling,
    canTag,

    ...ownProps,
  };
};

export const mapDispatchToProps = {
  updateTalent,
  addTalentEmail,
  addTalentPhone,
  removeTalentEmail,
  removeTalentPhone,
  updateTalentEmail,
  updateTalentPhone,
  sendTalentEmailVerification,
  cancelTalent,
};





export class TalentInfoForm extends Component {

  state = {
    isConfirmRemoveDialogOpen: false,
  }

  removeTalent = (confirmed=false) => {
    if(!confirmed) {
      this.setState({isConfirmRemoveDialogOpen:true});
      return;
    }

    this.setState({isConfirmRemoveDialogOpen:false});
    
    if(!this.props.talent.userId && this.props.talentId) {
      this.props.updateTalent(this.props.talentId, {status:'CANCELED'});
      return;
    }

    if(!this.props.talent.userId || !this.props.talentId) {
      return;
    }
    try {
      skyboltsnow.subscription(this.props.talent.userId).cancelSubscription(this.props.talentId);
    }
    catch(e) {
      alert(e.message || "There was an error canceling this subscription.");
    }
  }

  render() {

    const {
      talentId,
      talent = {},
      emails,
      phones,
      canAdmin,
      agency = {},
      isOwnTalent,
      useBillCom,
      canBilling,
      canTag,
      muiTheme:{ palette },
    } = this.props;

    const talentFields = agency.talentFields || defaultTalentFields;



    const update = (update) => {
      this.props.updateTalent(talentId, update);
    };
    const addEmail = () => this.props.addTalentEmail(talentId);
    const updateEmail = (emailId, update) => this.props.updateTalentEmail(talentId, emailId, update);
    const removeEmail = (emailId) => this.props.removeTalentEmail(talentId, emailId);
    const addPhone = () => this.props.addTalentPhone(talentId);
    const updatePhone = (phoneId, update) => this.props.updateTalentPhone(talentId, phoneId, update);
    const removePhone = (phoneId) => this.props.removeTalentPhone(talentId, phoneId);
    const verifyEmail = (emailId) => this.props.sendTalentEmailVerification(talentId, emailId);


    // Build a field list.

    const fields = Object.keys(talentFields || {})
      .map(id => ({...talentFields[id], id}))
      .filter(field => field.category !== "system")
      .filter(field => !isOwnTalent || field.isEditableByTalent)
      .reduce((all, field) => {

        // If this field has a condition and that condition isn't met,
        // don't add it to the fields object.

        if(!!field.condition) {
          if(talent[field.condition.field] !== field.condition.value) {
            return all;
          }
        }

        const category = field.category || 'Other';
        const fields = all[category] || {};
        return {
          ...all,
          [category]: {
            ...fields,
            [field.id]: field
          }
        };
      }, {});

    return (
      <div className="talentinfo-form talentinfo-form-row">

        <div className="talentinfo-form-col">

          <Subtitle>General</Subtitle>
          <Page pad={true}>
            {(!!talent.firstName || !talentFields.firstName || talentFields.firstName.isEnabled !== false) &&
              <Field label={talentFields.firstName ? talentFields.firstName.name : "First Name"}>
                <TextField
                  name="firstName"
                  style={{maxWidth:600}}
                  fullWidth
                  value={talent.firstName || ""}
                  onChange={(e, firstName) => update({firstName})}
                />
              </Field>
            }

            {(!!talent.middleName || !talentFields.middleName || talentFields.middleName.isEnabled !== false) &&
              <Field label={talentFields.middleName ? talentFields.middleName.name : "Middle Name"}>
                <TextField
                  name="middleName"
                  style={{maxWidth:600}}
                  fullWidth
                  value={talent.middleName || ""}
                  onChange={(e, middleName) => update({middleName})}
                />
              </Field>
            }

            {(!!talent.lastName || !talentFields.lastName || talentFields.lastName.isEnabled !== false) &&
              <Field label={talentFields.lastName ? talentFields.lastName.name : "Last Name"}>
                <TextField
                  name="lastName"
                  style={{maxWidth:600}}
                  fullWidth
                  value={talent.lastName || ""}
                  onChange={(e, lastName) => update({lastName})}
                />
              </Field>
            }

            <Field label={"Legal Name"}>
                <TextField
                  name="legalName"
                  style={{maxWidth:600}}
                  fullWidth
                  value={talent.legalName || ""}
                  onChange={(e, legalName) => update({legalName})}
                />
              </Field>

            {(!!talent.ssn || !talentFields.ssn || talentFields.ssn.isEnabled !== false) &&
              <Field label={talentFields.ssn ? talentFields.ssn.name : "SSN"}>
                <SSNField
                  name="ssn"
                  style={{maxWidth:600, paddingTop:16}}
                  underline={true}
                  initialValue={talent.ssn || ""}
                  onChange={ssn => update({ssn})}
                />
              </Field>
            }

            {(!talentFields.dob || talentFields.dob.isEnabled !== false) &&
              <Field label={talentFields.dob ? talentFields.dob.name : "Date of Birth"}>
                <DateField
                  name="dob"
                  style={{maxWidth:600, paddingTop:16}}
                  underline={true}
                  initialValue={talent.dob}
                  onChange={dob => update({dob})}
                />
              </Field>
            }

            {canTag && !!agency.divisions && agency.divisions.length > 0 &&
              <Field label="Divisions">
                <SelectField
                  multiple
                  fullWidth
                  maxHeight={200}
                  style={{maxWidth:600}}
                  value={talent.divisions || []}
                  onChange={(e, i, divisions) => update({divisions})}>
                  {agency.divisions.map(name =>
                    <MenuItem
                      key={name}
                      insetChildren={true}
                      checked={!!talent.divisions && talent.divisions.indexOf(name) > -1}
                      value={name}
                      primaryText={name}
                    />
                  )}
                </SelectField>
              </Field>
            }

            {canTag && !!agency.talentTags && agency.talentTags.length > 0 &&
              <Field label="Labels">
                <SelectField
                  multiple
                  fullWidth
                  maxHeight={200}
                  style={{maxWidth:600}}
                  value={talent.tags || []}
                  onChange={(e, i, tags) => update({tags})}>
                  {uniq([
                    ...(agency.talentTags || []),
                    ...(talent.tags || [])
                  ]).map(name =>
                    <MenuItem
                      key={name}
                      insetChildren={true}
                      checked={!!talent.tags && talent.tags.indexOf(name) > -1}
                      value={name}
                      primaryText={name}
                    />
                  )}
                </SelectField>
              </Field>
            }

            {(!talentFields.contactName || talentFields.contactName.isEnabled !== false) &&
              <Field label={talentFields.contactName ? talentFields.contactName.name : "Contact Name"}>
                <TextField
                  name="contactName"
                  style={{maxWidth:600}}
                  fullWidth
                  value={talent.contactName}
                  onChange={(e, contactName) => update({contactName})}
                />
              </Field>
            }

            <Field label="Email">
              {Object.keys(emails).map(emailId =>
                <div key={emailId}>
                  <div style={{display:'flex', alignItems:'flex-start', maxWidth:600}}>
                    <Button
                      tooltip="Use this address"
                      icon={emails[emailId].doContact !== false ? 'check' : 'uncheck'}
                      onClick={() => updateEmail(emailId, {doContact: !emails[emailId].doContact})}
                    />

                    <SelectField
                      name={`label${emailId}`}
                      hintText="Name"
                      value={emails[emailId].label || ""}
                      style={{flex:1}}
                      onChange={(e, i, label) => updateEmail(emailId, {label})}>

                      <MenuItem value="Home" primaryText="Home"/>
                      <MenuItem value="Work" primaryText="Work"/>
                      <MenuItem value="Mom Home" primaryText="Mom Home"/>
                      <MenuItem value="Mom Work" primaryText="Mom Work"/>
                      <MenuItem value="Dad Home" primaryText="Dad Home"/>
                      <MenuItem value="Dad Work" primaryText="Dad Work"/>

                    </SelectField>

                    <TextField
                      name={`email${emailId}`}
                      hintText="Email"
                      style={{flex:3}}
                      value={emails[emailId].email || ""}
                      onChange={(e, email) => updateEmail(emailId, {email:email.trim()})}
                    />

                    <Button
                      tooltip="Remove email"
                      icon="remove"
                      onClick={() => removeEmail(emailId)}
                    />
                  </div>

                  {emails[emailId].doContact !== false && !!emails[emailId].verificationSent && !emails[emailId].isVerified && emails[emailId].email &&
                    <div className="talentinfo-field-alert" style={{backgroundColor:palette.accent700}}>
                      <div style={{flex:1}}>
                        We've sent you a verification link.<br/>
                        Please check your email.
                      </div>
                      <Button
                        label="Resend"
                        icon="send"
                        color="white"
                        labelStyle={{color:'white'}}
                        onClick={() => verifyEmail(emailId)}
                      />
                    </div>
                  }

                  {emails[emailId].doContact !== false && !emails[emailId].verificationSent && !emails[emailId].isVerified && emails[emailId].email &&
                    <div className="talentinfo-field-alert" style={{backgroundColor:palette.warning}}>
                      <div style={{flex:1}}>Please verify your email address.</div>
                      <Button
                        label="Verify"
                        icon="send"
                        color="white"
                        labelStyle={{color:'white'}}
                        onClick={() => verifyEmail(emailId)}
                      />
                    </div>
                  }

                </div>
              )}
              <Button
                icon="plus"
                label="Add Email Address"
                style={{marginTop:6, marginLeft:4}}
                onClick={addEmail}
              />
            </Field>

            <Field label="Phone">
              {Object.keys(phones).map(phoneId =>
                <div key={phoneId}>
                  <div style={{display:'flex', alignItems:'flex-start', maxWidth:600}}>
                    <Button
                      tooltip="Use this number"
                      icon={phones[phoneId].doContact !== false ? 'check' : 'uncheck'}
                      onClick={() => updatePhone(phoneId, {doContact: !phones[phoneId].doContact})}
                    />

                    <SelectField
                      name={`label${phoneId}`}
                      hintText="Name"
                      value={phones[phoneId].label || ""}
                      style={{flex:1}}
                      onChange={(e, i, label) => updatePhone(phoneId, {label})}>

                      <MenuItem value="Home" primaryText="Home"/>
                      <MenuItem value="Work" primaryText="Work"/>
                      <MenuItem value="Cell" primaryText="Cell"/>
                      <MenuItem value="Mom Home" primaryText="Mom Home"/>
                      <MenuItem value="Mom Work" primaryText="Mom Work"/>
                      <MenuItem value="Mom Cell" primaryText="Mom Cell"/>
                      <MenuItem value="Dad Home" primaryText="Dad Home"/>
                      <MenuItem value="Dad Work" primaryText="Dad Work"/>
                      <MenuItem value="Dad Cell" primaryText="Dad Cell"/>

                    </SelectField>

                    <TextField
                      name={`number{phoneId}`}
                      hintText="Number"
                      style={{flex:3}}
                      value={phones[phoneId].number || ""}
                      onChange={(e, number) => updatePhone(phoneId, {number})}
                    />

                    <Button
                      tooltip="Remove phone number"
                      style={{flex:'0 0 10%'}}
                      icon="remove"
                      onClick={() => removePhone(phoneId)}
                    />
                  </div>

                </div>
              )}
              <Button
                icon="plus"
                label="Add Phone Number"
                style={{marginTop:6, marginLeft:4}}
                onClick={addPhone}
              />
            </Field>

            <Field label="Address">
              <TextField
                name="street"
                hintText="Street Address"
                fullWidth
                style={{maxWidth:600}}
                value={talent.street}
                onChange={(e, street) => update({street})}
              />
              <TextField
                name="city"
                style={{width:'50%', maxWidth:300, minWidth:120}}
                hintText="City"
                value={talent.city}
                onChange={(e, city) => update({city})}
              />
              <TextField
                name="state"
                style={{width:'25%', maxWidth:150, minWidth:60}}
                hintText="State"
                value={talent.state}
                onChange={(e, state) => {
                  state = state.slice(0,2);
                  update({state});
                }}
              />
              <TextField
                name="zip"
                style={{width:'25%', maxWidth:150, minWidth:60}}
                hintText="Zip"
                value={talent.zip}
                onChange={(e, zip) => update({zip})}
              />
            </Field>

            {useBillCom &&
              <Field label="Bank Account">
                <div style={{paddingTop:16}}>
                  <BankAccount/>
                </div>
              </Field>
            }

          </Page>

          {canAdmin && talent.status !== 'CANCELED' &&
            <div style={{paddingTop:16, paddingBottom:32}}>
              <Button
                raised={true}
                label="Cancel Talent"
                onClick={() => this.removeTalent()}
                backgroundColor={palette.error}
              />
            </div>
          }

          <Dialog
            open={this.state.isConfirmRemoveDialogOpen}
            contentStyle={{maxWidth:500}}
            onRequestClose={()=> this.setState({isConfirmRemoveDialogOpen:false})}
            actions={[
              <Button label="No" onClick={()=> this.setState({isConfirmRemoveDialogOpen:false})} style={{marginRight:8}}/>,
              <Button raised={true} primary={true} label="Yes, I'm sure" onClick={() => this.removeTalent(true)}/>
            ]}>
            <Text title paragraph>Are you sure?</Text>
            You are about to cancel {talent.firstName || ""} {talent.lastName || ""}.<br/>
            <br/>
            {!!talent.plan && !!talent.plan.talentSetup &&
              <span>{talent.firstName || ""} {talent.lastName || ""} will be charged a one-time ${talent.plan.talentSetup.toFixed(2)} setup fee when they restart their account.</span>
            }
          </Dialog>

        </div>

        <div className="talentinfo-form-col">

          {Object.keys(fields).map(category =>
            <div className="talentinfo-category" key={category}>
              <Subtitle>{category}</Subtitle>
              <Page pad={true}>

                {Object.keys(fields[category])
                  .map(fieldId => fields[category][fieldId])
                  .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
                  .map(field =>
                    <Field key={field.id} label={field.name}>

                      {field.type === 'number' &&
                        <NumberField
                          name={field.id}
                          style={{maxWidth:600}}
                          fullWidth
                          value={talent[field.id]}
                          units={field.units}
                          min={field.min}
                          max={field.max}
                          onChange={(e, val) => update({[field.id]:val})}
                        />
                      }

                      {field.type === 'text' &&
                        <TextField
                          name={field.id}
                          style={{maxWidth:600}}
                          fullWidth
                          value={talent[field.id]}
                          onChange={(e, val) => update({[field.id]:val})}
                        />
                      }

                      {field.type === 'date' &&
                        <DateField
                          name={field.id}
                          style={{maxWidth:600, paddingTop:16}}
                          underline={true}
                          initialValue={talent[field.id] || ""}
                          onChange={date => update({[field.id]:date})}
                        />
                      }

                      {field.type === "select" &&
                        <InputSelect
                          key={field.id}
                          strict={!!field.options}
                          options={(field.options || []).map(v => ({label:v, value:v}))}
                          clearable={true}
                          value={talent[field.id] || ""}
                          multiple={field.multiple}
                          onChange={val => update({[field.id]:val || null})}
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: palette.primary300,
                            borderBottom: `1px solid ${palette.primary300}`,
                          }}
                        />
                      }

                      {field.type === 'range' &&
                        <RangeField
                          key={field.id}
                          initialValue={talent[field.id]}
                          onChange={(e, val) => update({[field.id]: val})}
                          {...field}
                        />
                      }

                      {!!field.tip && (
                        <IconMenu
                          iconButtonElement={
                            <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                              <div style={{color:palette.accent300, fontSize:12}}>{field.tipTitle || "Tip"}</div>
                            </div>
                          }
                          anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                          targetOrigin={{horizontal: 'right', vertical: 'top'}}
                          useLayerForClickAway={true}>

                          <div style={{padding:8}} dangerouslySetInnerHTML={{__html:field.tip}}/>

                        </IconMenu>
                      )}

                      {field.hint && (
                        <div style={{display:'flex', alignItems:'center'}}>
                          <div style={{color:"#aaaaaa", fontSize:12}}>{field.hint}</div>
                        </div>
                      )}

                    </Field>
                  )
                }

              </Page>
            </div>
          )}

        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(TalentInfoForm));
